<template>
  <div class="allmsgclass">
    <navigation msg="人脸验证"></navigation>
    <div class="heng"></div>
    <div class="ren1">请拍摄一张正脸照片</div>
    <div class="ren2">
      <img src="../../assets/faceupload_1.png" alt="" />
      <div>请将镜头对准您的脸，按提示操作</div>
    </div>
    <div class="ren3">
      <img src="../../assets/faceupload_2.png" alt="" />
      <div class="ren4">
        <div>不能戴眼镜</div>
        <div>不能戴帽子</div>
        <div>不能光线太暗</div>
      </div>
    </div>
    <div class="abclass">
      <label for="fileid"><div class="paishe">开始拍摄</div></label>
      <input @change="selectfile" type="file" id="fileid" accept="image/*"  capture="camera" />
    </div>
  </div>
</template>
<script>
export default {
    methods:{
        selectfile(){
            let file = document.getElementById("fileid");
            console.log(file.files);
        }
    }
};
</script>
<style scoped>
#fileid{
    opacity: 0;
    position: fixed;
    bottom: -1rem;
}
.abclass {
  position: absolute;
  width: 100%;
  bottom: 0.5rem;
}
.paishe {
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.42rem;
  padding: 0.18rem 0;
  margin: 0 0.8rem;
  text-align: center;
  font-size: 0.28rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.48rem;
}
.ren4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.12rem 0.13rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.34rem;
}
.ren3 {
  margin: 7vh 0.64rem;
}
.ren3 img {
  width: 100%;
}
.ren2 {
  font-size: 0.24rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.34rem;
  text-align: center;
}
.ren2 img {
  width: 2.88rem;
  margin-bottom: 0.26rem;
  margin-top: 6vh;
}
.ren1 {
  font-size: 0.4rem;
  font-weight: 800;
  color: #303133;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 5vh;
}
.heng {
  height: 1px;
  background-color: #eeeeee;
}
.allmsgclass {
  background-color: white;
  height: 100%;
  position: relative;
  overflow: hidden;
}
</style>